import { tw } from '@backmarket/utils/string/tw'

import type {
  Background,
  BackgroundProps,
  BackgroundTheme,
  ImageBackground,
} from './Background.types'

const moodClassMap = {
  blush: tw`mood-blush`,
  bush: tw`mood-bush`,
  coffee: tw`mood-coffee`,
  cornflower: tw`mood-cornflower`,
  emerald: tw`mood-emerald`,
  purple: tw`mood-purple`,
  tangaroa: tw`mood-tangaroa`,
  violet: tw`mood-violet`,
  light: tw`mood-inverse`,
  dark: tw`mood-main`,
  white: tw`mood-main`,
} as const satisfies Record<BackgroundTheme | 'light' | 'dark', string>

export type MoodClass = (typeof moodClassMap)[keyof typeof moodClassMap]

const THEMES_WITH_DARK_TEXT: BackgroundTheme[] = [
  'purple',
  'emerald',
  'blush',
  'cornflower',
]

function getMoodClass(options: BackgroundProps): string {
  if (!options.background) {
    return moodClassMap.dark
  }

  if ('image' in options.background) {
    return options.background.mode === 'light'
      ? moodClassMap.dark
      : moodClassMap.light
  }

  if ('theme' in options.background) {
    if (THEMES_WITH_DARK_TEXT.includes(options.background.theme)) {
      return moodClassMap.dark
    }

    return moodClassMap[options.background.theme]
  }

  return moodClassMap.dark
}

const isImageBackground = (
  background?: BackgroundProps['background'],
): background is ImageBackground => {
  return !!background && 'image' in background
}
const getRoundedClass = (rounded: BackgroundProps['rounded']) => {
  if (rounded === 'always') return tw`rounded-lg`
  if (rounded === 'desktop') return tw`md:rounded-lg`

  return ''
}
function getContainerClass(options: BackgroundProps) {
  const roundedClass = getRoundedClass(options.rounded)
  if (
    options?.background &&
    'theme' in options.background &&
    !!options.background.theme
  ) {
    if (options.background.theme === 'white')
      return `bg-surface-default-low ${moodClassMap[options.background.theme]} ${roundedClass}`

    return `bg-surface-default-mid ${moodClassMap[options.background.theme]} ${roundedClass}`
  }

  return roundedClass
}

export const hasBackground = (
  sectionBanner: BackgroundProps,
): sectionBanner is {
  background: Background
} => 'background' in sectionBanner && !!sectionBanner.background

export function getPaddingClass(options: BackgroundProps) {
  const horizontalPadding = 'px-24 md:px-72'
  if (!hasBackground(options)) {
    return `${horizontalPadding} py-32`
  }

  return isImageBackground(options.background)
    ? `${horizontalPadding} py-32 md:py-72`
    : `${horizontalPadding} py-32`
}

function buildOverlay(options: BackgroundProps) {
  if (isImageBackground(options.background)) {
    const overlayClass: string[] = []

    overlayClass.push(
      options.background.mode === 'light' ? tw`mood-main` : tw`mood-inverse`,
    )

    overlayClass.push(getRoundedClass(options.rounded))

    return { hasOverlay: true, overlayClass }
  }

  return { hasOverlay: false, overlayClass: '' }
}

function getBackgroundImageStyle(options: BackgroundProps) {
  if (isImageBackground(options.background)) {
    return `url(${options.background.image.src})`
  }

  return ''
}

export function getBackgroundStyles(options: BackgroundProps) {
  const containerClass = getContainerClass(options)
  const moodClass = getMoodClass(options)
  const paddingClass = getPaddingClass(options)

  const overlay = buildOverlay(options)

  const backgroundImage = getBackgroundImageStyle(options)

  return {
    containerClass,
    moodClass,
    paddingClass,
    ...overlay,
    backgroundImage,
  }
}
